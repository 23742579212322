import { ExclamationCircleIcon } from '@heroicons/react/24/outline'
import { useRef, useState } from 'react'
import Button from '../Button'
import NiceModal from '@ebay/nice-modal-react'
import { Card } from '@mui/material'
import { ModalContainerAnimateDown } from '../gsys-ui/ModalContainer/ModalContainer'

export const WarningModal = NiceModal.create((innerProps) => {
  const modalContainer = useRef(null)
  const [isLoading, setIsLoading] = useState(false)

  const handleClose = () => {
    modalContainer.current?.closeModal()
  }

  const handleSubmit = async () => {
    setIsLoading(true)
    await innerProps.submit()
    setIsLoading(false)
    handleClose()
  }

  return (
    <ModalContainerAnimateDown ref={modalContainer}>
      <Card className="w-[350px] p-4">
        <div className="flex flex-col items-center space-y-4">
          <div className="p-3 bg-red-200 rounded-full">
            <ExclamationCircleIcon className="w-8 h-8 text-red-700" />
          </div>
          <div className="text-center">{innerProps.message}</div>
          <div className="font-bold">This cannot be undone!</div>
          <div className="flex justify-between items-center w-full">
            <Button onClick={handleClose}>Cancel</Button>
            <Button loading={isLoading} color="red" onClick={handleSubmit}>
              Submit
            </Button>
          </div>
        </div>
      </Card>
    </ModalContainerAnimateDown>
  )
})

export default WarningModal
