import { Box, Button, Card, CardContent, Grid, Typography } from '@mui/material'
import { ModalContainerAnimateDown } from './gsys-ui/ModalContainer/ModalContainer'
import NiceModal from '@ebay/nice-modal-react'
import { deleteLicense, revokeLicense } from '../lib/api'
import { useRef } from 'react'
import { removeDuplicates, successToast } from '../lib/util'
import dayjs from 'dayjs'
import useApi from '../hooks/useApi'

const timeAgo = (date) => dayjs(date).fromNow()

const LicensesModal = NiceModal.create(({ garage, refetch: refetchMain }) => {
  const modalContainer = useRef(null)

  const {
    isLoading,
    data: customers,
    refetch
  } = useApi({
    url: 'https://vision-web-api-test.azurewebsites.net/api/connectplus/garageuserswithlicences'
  })

  if (isLoading || !customers) return null

  const customersNoDups = removeDuplicates(customers, 'GarageUserId')
  const licenses = customersNoDups.filter((c) => c.GarageName === garage)

  const handleRevokeLicense = (licenseId) => {
    console.log('Revoking license with ID:', licenseId)

    NiceModal.show('deleteModal', {
      text: `Are you sure you want to revoke this license?`,
      submit: async () => {
        await revokeLicense(licenseId)
        await refetch()
        refetchMain()
        successToast('License has been revoked.')
      }
    })
  }
  const handleDeleteLicense = (garageId, licenseId) => {
    console.log('Deleting license with ID:', licenseId)

    NiceModal.show('deleteModal', {
      text: `Are you sure you want to delete this license?`,
      submit: async () => {
        await deleteLicense(garageId, licenseId)
        await refetch()
        refetchMain()
        successToast('License has been deleted.')
      }
    })
  }

  return (
    <ModalContainerAnimateDown ref={modalContainer}>
      <Card sx={{ width: '1100px', maxHeight: '80vh', overflowY: 'scroll' }}>
        <div className="p-4">
          <Typography variant="h6" component="h2">
            Licenses for {garage}
          </Typography>
          <Grid container spacing={3} sx={{ mt: 2 }}>
            {licenses.map((customer) => (
              <Grid item xs={12} sm={6} md={4} key={customer.GarageUserId}>
                <Card sx={{ minHeight: '156px', height: '100%' }}>
                  <CardContent className="flex flex-col justify-between" sx={{ height: '100%' }}>
                    <div>
                      <Typography variant="h6">{customer.GarageUserName}</Typography>
                      {customer.EntryCode && (
                        <div className="text-lg">
                          License code:
                          <span className="font-semibold tracking-widest">
                            {' '}
                            {customer.EntryCode}
                          </span>
                        </div>
                      )}
                      <Typography color="textSecondary">
                        Last Use: {timeAgo(customer.LastUse)}
                      </Typography>
                    </div>
                    <Box
                      sx={{
                        mt: 3,
                        display: 'flex',
                        justifyContent: 'space-between',
                        alignItems: 'flex-end'
                      }}
                    >
                      <Button
                        variant="contained"
                        color="error"
                        disabled={!!customer.EntryCode}
                        onClick={() => handleRevokeLicense(customer.DeviceLicenseId)}
                        sx={{ mr: 2 }}
                      >
                        Revoke
                      </Button>
                      <Button
                        variant="contained"
                        disabled={!customer.EntryCode}
                        onClick={() =>
                          handleDeleteLicense(customer.GarageUserId, customer.DeviceLicenseId)
                        }
                      >
                        Delete
                      </Button>
                    </Box>
                  </CardContent>
                </Card>
              </Grid>
            ))}
          </Grid>
        </div>
      </Card>
    </ModalContainerAnimateDown>
  )
})

export default LicensesModal
