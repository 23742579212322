import { create } from 'zustand'

const useNotificationStore = create((set) => ({
  permission: Notification.permission,
  requestPermission: async () => {
    try {
      const permission = await Notification.requestPermission()
      set({ permission })
      return permission
    } catch (error) {
      console.error('Error:', error)
      return 'denied'
    }
  },
  notify: (title, body) => {
    if (Notification.permission !== 'granted') return false
    new Notification(title, { body })
    return true
  }
}))

export default useNotificationStore
