import haxios from './haxios'
import { blobToBase64, objectToFormData } from './util'

const BASE_URL = 'https://api.aa-vision.com'
const AUTOMMAPIR_URL = 'https://autommapir.connectplus.parts'

export const getApirAddr = async (companyId) => {
  const res = await haxios.get(`${AUTOMMAPIR_URL}/api/getCompany/${companyId}`)
  return res[0]
}

export const getCompanyInfo = async () => {
  const res = await haxios.get(BASE_URL + '/api/user')
  return res
}

export const getImageFromBlobId = async (blobId) => {
  const res = await haxios.get(`${BASE_URL}/api/image/${blobId}`, { responseType: 'blob' })
  const blob = res
  const base64 = await blobToBase64(blob)
  return base64
}

export const sendMessage = async (enquiryId, msg, img) => {
  let body = {
    EnquiryID: enquiryId,
    Text: msg,
    Image: img
  }

  await haxios({
    url: BASE_URL + '/api/connectplus/factor/message',
    method: 'POST',
    data: objectToFormData(body)
  })
}

export const revokeLicense = async (licenseId) => {
  return haxios({
    url: BASE_URL + '/api/devicelicense/detach/' + licenseId,
    method: 'GET'
  })
}

export const deleteLicense = async (garageId, licenseId) => {
  return haxios({
    url: BASE_URL + `/api/connectplus/garageuser/${garageId}/${licenseId}`,
    method: 'DELETE'
  })
}
