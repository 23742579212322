import { Loader, Button as MantineButton } from '@mantine/core'
import clsx from 'clsx'

const Button = ({ children, loading, ...props }) => {
  return (
    <MantineButton {...props} disabled={loading || props.disabled} className={clsx(props.className, 'relative')}>
      {loading && (
        <div className="flex absolute inset-0 justify-center items-center">
          <Loader color="rgba(255, 255, 255, 0.8)" size={25} />
        </div>
      )}
      <div className={clsx(loading && 'text-transparent')}>{children}</div>
    </MantineButton>
  )
}

export default Button
