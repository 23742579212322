import { useEffect, useState, useRef } from 'react'
import haxios from '../lib/haxios'

const useApi = ({
  url,
  method = 'get',
  data: body,
  refetch = null,
  enabled = true,
  initial = null
}) => {
  const [isLoading, setIsLoading] = useState(true)
  const [isFetching, setIsFetching] = useState(true)
  const [data, setData] = useState(initial)
  const isRequestInProgress = useRef(false)
  const initialLoadComplete = useRef(false)

  const loadData = async () => {
    if (!url || isRequestInProgress.current) return

    let res
    isRequestInProgress.current = true
    setIsFetching(true)

    try {
      if (method.toLowerCase() === 'get') {
        res = await haxios.get(url, { params: body })
        console.log('GET response:', res)
      } else if (method.toLowerCase() === 'post') {
        res = await haxios.post(url, body)
        console.log('POST response:', res)
      }

      setData(res)
    } catch (error) {
      console.error('API request error:', error)
    } finally {
      setIsFetching(false)
      setIsLoading(false)
      isRequestInProgress.current = false
      initialLoadComplete.current = true
    }
  }

  useEffect(() => {
    if (enabled && url) loadData()
  }, [url, JSON.stringify(body), enabled])

  useEffect(() => {
    if (!refetch || !url) return

    const interval = setInterval(() => {
      if (enabled && initialLoadComplete.current && !isRequestInProgress.current) loadData()
    }, refetch)

    return () => clearInterval(interval)
  }, [refetch, JSON.stringify(body), enabled, url])

  useEffect(() => {
    if (enabled) setIsLoading(true)
  }, [enabled])

  return { isLoading, isFetching, data, refetch: loadData }
}

export default useApi
