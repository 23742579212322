import React, { useRef, useState, useContext } from 'react'
import NiceModal, { useModal } from '@ebay/nice-modal-react'
import {
  Box,
  Button,
  Typography,
  CircularProgress,
  Select,
  InputLabel,
  MenuItem,
  FormControl,
  FormHelperText
} from '@mui/material'
import { ModalContainerAnimateDown } from './gsys-ui/ModalContainer/ModalContainer'
import haxios from '../lib/haxios'
import { GlobalContext } from '../context/GlobalContext' // Add this import

const API_URL = 'https://api.aa-vision.com'
const MELK_API_URL = 'https://melk.connectplus.parts'

const ConfirmationModal = NiceModal.create(({ enquiryId, onCancelComplete }) => {
  console.log('Enquiry ID:', enquiryId)

  const modal = useModal()
  const modalContainer = useRef(null)
  const [loading, setLoading] = useState(false)
  const [error, setError] = useState(null)
  const [selectedReason, setSelectedReason] = useState('')
  const [reasonError, setReasonError] = useState(false)
  const { token, username, uinfo, cinfo } = useContext(GlobalContext) // Add this line

  const handleCancelEnquiry = async () => {
    if (!selectedReason) {
      setReasonError(true)
      return
    }

    setLoading(true)
    setError(null)
    setReasonError(false)

    try {
      console.log('Starting fetch...')

      // Step 1: Get enquiry details
      const enquiryDetails = await haxios.get(
        `${API_URL}/api/connectplus/factor/enquiry/${enquiryId}`,
        {
          headers: {
            Authorization: `Bearer ${token}`
          }
        }
      )

      console.log('Enquiry Details:', enquiryDetails)

      if (!enquiryDetails?.EnquiryId) {
        console.error('Invalid enquiry details:', enquiryDetails)
        throw new Error('Invalid enquiry details received')
      }

      // Step 2: Record cant-supply reason
      const cantSupplyPayload = {
        EnquiryID: enquiryId,
        Reason: selectedReason,
        LoggedBy: cinfo.Username
      }

      console.log('Recording cant-supply reason:', cantSupplyPayload)
      await haxios.post(
        `${MELK_API_URL}/api/salesassist/cplusassist/cantsupply`,
        cantSupplyPayload,
        {
          headers: {
            Authorization: `Bearer ${token}`
          }
        }
      )

      // Step 3: Post enquiry response with "Unavailable"
      const headerId = crypto.randomUUID()
      const responsePayload = {
        Enquiry: {
          ...enquiryDetails,
          Status: 'Quoted'
        },
        ResponseHeader: {
          ResponseHeaderId: headerId,
          EnquiryId: enquiryId,
          TotalCost: 0,
          UserId: enquiryDetails.UserId,
          UserName: username || enquiryDetails.Username || enquiryDetails.PortalLockUsername,
          Make: enquiryDetails.Make || '',
          Model: enquiryDetails.Model || '',
          Engine: enquiryDetails.Engine || '',
          Fuel: enquiryDetails.Fuel || '',
          GBox: enquiryDetails.GBox || '',
          Notes: selectedReason,
          Automated: true,
          AutomatedQuote: true
        },
        ResponseDetail: [
          {
            ResponseHeaderId: headerId,
            ResponseDetailId: crypto.randomUUID(),
            PartNumber: 'UNAVAILABLE',
            Notes: selectedReason,
            Qty: 0,
            UnitPrice: 0,
            DeliveryDate: new Date().toISOString().split('T')[0]
          }
        ]
      }

      console.log('Posting response payload:', responsePayload)
      await haxios.post(`${API_URL}/api/connectplus/factor/enquiryresponse/`, responsePayload, {
        headers: {
          Authorization: `Bearer ${token}`
        }
      })

      // Step 4: Cancel the enquiry
      const cancelPayload = {
        Enquiry: {
          ...enquiryDetails,
          Status: 'Cancelled',
          ResponseHeaderNotes: selectedReason,
          CancelledBy: username || enquiryDetails.Username || enquiryDetails.PortalLockUsername
        },
        ResponseHeader: {
          ResponseHeaderId: headerId,
          EnquiryId: enquiryId,
          Make: enquiryDetails.Make || '',
          Model: enquiryDetails.Model || '',
          Engine: enquiryDetails.Engine || '',
          Fuel: enquiryDetails.Fuel || '',
          GBox: enquiryDetails.GBox || '',
          Notes: selectedReason,
          TotalCost: enquiryDetails.TotalCost || 0,
          UserId: enquiryDetails.UserId,
          UserName: username || enquiryDetails.Username || enquiryDetails.PortalLockUsername
        }
      }

      console.log('Posting cancel payload:', cancelPayload)
      await haxios.post(`${API_URL}/api/connectplus/factor/enquiry/cancel`, cancelPayload, {
        headers: {
          Authorization: `Bearer ${token}`
        }
      })

      // Success handling
      setLoading(false)
      modal.remove()
      if (onCancelComplete) onCancelComplete()
    } catch (err) {
      console.error('Full error:', err)
      setLoading(false)
      setError(err.message || 'An error occurred while canceling the enquiry.')
    }
  }

  const handleClose = () => {
    modal.remove()
  }

  const reasons = [
    'Main Dealer Only',
    'No stock anywhere',
    'Not available through MMS',
    'Account Issue – please call in',
    'Vehicle reg not working, please send again'
  ]

  return (
    <ModalContainerAnimateDown ref={modalContainer}>
      <Box
        sx={{
          padding: 3,
          width: '400px',
          backgroundColor: 'white',
          borderRadius: '8px',
          boxShadow: 24
        }}
      >
        <Typography variant="h6" sx={{ mb: 2 }}>
          Are you sure you can't supply this item?
        </Typography>

        <FormControl fullWidth error={reasonError} sx={{ mb: 2 }}>
          <InputLabel>Select a reason</InputLabel>
          <Select
            value={selectedReason}
            onChange={(e) => setSelectedReason(e.target.value)}
            displayEmpty
          >
            <MenuItem value="" disabled>
              Select a reason
            </MenuItem>
            {reasons.map((reason, index) => (
              <MenuItem key={index} value={reason}>
                {reason}
              </MenuItem>
            ))}
          </Select>
          {reasonError && <FormHelperText>Please select a reason</FormHelperText>}
        </FormControl>

        {error && (
          <Typography color="error" variant="body2" sx={{ mb: 2 }}>
            {error}
          </Typography>
        )}

        <Box sx={{ display: 'flex', justifyContent: 'space-between' }}>
          <Button variant="outlined" onClick={handleClose} disabled={loading}>
            NO
          </Button>
          <Button
            variant="contained"
            color="error"
            onClick={handleCancelEnquiry}
            disabled={loading || !selectedReason}
          >
            {loading ? <CircularProgress size={24} /> : 'YES'}
          </Button>
        </Box>
      </Box>
    </ModalContainerAnimateDown>
  )
})

export default ConfirmationModal
