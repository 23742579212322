import React from 'react'
import { BellAlertIcon, BellIcon } from '@heroicons/react/24/outline'
import useNotificationStore from '../stores/useNotificationStore'
import { Button, Card } from '@mantine/core'

const NotificationBanner = () => {
  const { permission, requestPermission } = useNotificationStore()

  if (permission !== 'default') {
    return null
  }

  return (
    <Card
      shadow="lg"
      className="fixed top-2 left-[50%] w-[350px] border border-gray-300 translate-x-[-50%]"
      style={{ zIndex: 10000 }}
    >
      <div className="flex items-center mb-2 border-bottom border-bottom-gray-300">
        <BellAlertIcon className="mr-2 w-8 h-8" />
        <div className="text-lg font-semibold">Enable notifications</div>
      </div>

      <div className="mb-3">
        We would like to show you notifications for order and message updates.
      </div>

      <div className="flex justify-end">
        <Button onClick={requestPermission}>Enable</Button>
      </div>
    </Card>
  )
}

export default NotificationBanner
