import * as yup from 'yup'

yup.addMethod(yup.string, 'alphanumeric', function () {
  return this.matches(/^[a-z\d\s]*$/i, 'Only A-Z, 0-9 and spaces are allowed')
})

yup.addMethod(yup.string, 'nullToEmptyString', function () {
  return this.nullable().transform((value, originalValue) => (originalValue === null ? '' : value))
})

yup.addMethod(yup.number, 'emptyStringToZero', function () {
  return this.transform((value, originalValue) => {
    if (originalValue === '') return 0
    return originalValue
  })
})

export default yup
