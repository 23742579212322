import axios from 'axios'
import join from 'url-join'

import { getApirAddr, getCompanyInfo } from './api'

export let token = localStorage.getItem('token')
export let apir = null
export let cinfo = null
const haxios = axios.create()
const isAbsoluteURLRegex = /^(?:\w+:)\/\//

export const getToken = async (username, password, shouldNavigate = true) => {
  try {
    const res = await axios({
      method: 'POST',
      url: `https://api.aa-vision.com/api/authenticate`,
      data: { Username: username, Password: password },
      headers: { 'Content-Type': 'application/json' }
    })

    token = res.data.token
    localStorage.setItem('token', token)
    return res
  } catch (error) {
    console.error('Error getting token', error)
    if (error.response && error.response.status === 406 && shouldNavigate) {
      if (window.location.pathname !== '/') window.location.href = '/'
    }
    throw error
  }
}

export const getCinfo = async () => {
  if (cinfo) return cinfo
  cinfo = await getCompanyInfo()
  return cinfo
}

export const getApir = async () => {
  if (apir) return apir

  if (!cinfo) {
    cinfo = await getCinfo()
    if (!cinfo) throw new Error('Company info could not be loaded')
  }

  if (cinfo.CompanyID === '85be2070-f82f-42e4-9f0e-83e60427c508') {
    apir = 'https://melk.connectplus.parts/api'
    return apir
  }

  let res
  try {
    res = await getApirAddr(cinfo.CompanyID)
  } catch (err) {
    throw new Error('APIR address could not be loaded from getApirAddr')
  }

  if (!res || !res.CompanyDomain) {
    throw new Error('APIR address response missing CompanyDomain')
  }

  if (window.location.hostname !== 'localhost') {
    if (window.location.hostname !== res.CompanyDomain) {
      throw new Error('This is not your server.')
    }
  }

  apir = `https://${res.CompanyDomain}/api`
  console.log('APIR:', apir)
  if (!apir) {
    window.location.href = '/error'
  }
  return apir
}

haxios.interceptors.request.use(
  async (config) => {
    if (!token) await getToken()
    config.headers.token = token
    config.headers.Authorization = `Bearer ${token}`

    if (!isAbsoluteURLRegex.test(config.url)) {
      if (!apir) {
        apir = await getApir()
      }
      if (!apir) {
        throw new Error('APIR URL could not be loaded')
      }
      config.url = join(apir, config.url)
    }

    return config
  },
  (error) => Promise.reject(error)
)

haxios.interceptors.response.use(
  (response) => response.data,
  async (error) => {
    console.log(error)
    const originalRequest = error.config
    if (!error.response) {
      return Promise.reject(error)
    }
    if (error.response.status === 401 && !originalRequest._retry) {
      originalRequest._retry = true
      await getToken()
      originalRequest.headers.token = token
      originalRequest.headers.Authorization = `Bearer ${token}`
      return haxios(originalRequest)
    }
    if (error.response.status === 401) {
      if (window.location.pathname !== '/') window.location.href = '/'
      return
    }
    return Promise.reject(error)
  }
)

export default haxios
