import { useRef, useState } from 'react'
import { yupResolver } from '@hookform/resolvers/yup'
import { Button, Grid, Text } from '@mantine/core'
import { useForm, Controller } from 'react-hook-form'
import { TextInput, FileInput } from 'react-hook-form-mantine'
import { DateTimePicker } from '@mantine/dates'
import yup from '../../lib/yup'
import { Checkbox } from '@mantine/core'
//import useBranchStore from '../stores/useBranchStore'
import haxios from '../../lib/haxios'
import { ModalContainerAnimateDown } from '../gsys-ui/ModalContainer/ModalContainer'
import { Card } from '@mui/material'
import NiceModal from '@ebay/nice-modal-react'
import useApi from '../../hooks/useApi'

const validation = yup.object().shape({
  title: yup.string().required('Title is required').min(3, 'Title must be at least 3 characters'),
  expiryTime: yup
    .date()
    .required('Expiry time is required')
    .test('future', 'Expiry time must be in the future', (value) => {
      if (!value) return true
      return value > new Date()
    }),
  pdf: yup
    .mixed()
    .required('PDF is required')
    .test('fileType', 'File must be a PDF', (value) => value && value.type === 'application/pdf'),
  branches: yup
    .array()
    .of(yup.string())
    .min(1, 'Select at least one branch')
    .required('Select at least one branch')
})

const PromotionUploadModal = NiceModal.create((innerProps) => {
  const [isLoading, setIsLoading] = useState(false)
  const [pdfUrl, setPdfUrl] = useState(null)
  const regions = []
  const modalContainer = useRef(null)

  const garages = useApi({
    url: 'https://vision-web-api-test.azurewebsites.net/api/connectplus/garageusers',
    initial: []
  })

  const garageList = garages.data
    .map((garage) => ({
      GarageName: garage.GarageName,
      CustomerKeyCode: garage.CustomerKeyCode
    }))
    .filter(
      (garage, index, self) =>
        self.findIndex((g) => g.CustomerKeyCode === garage.CustomerKeyCode) === index
    )
    .sort((a, b) => a.GarageName.localeCompare(b.GarageName))

  const handleClose = () => {
    modalContainer.current?.closeModal()
  }

  const {
    control,
    handleSubmit,
    setValue,
    formState: { errors }
  } = useForm({
    //resolver: yupResolver(validation),
    defaultValues: innerProps?.defaultValues || {
      title: '',
      expiryTime: null,
      pdf: null,
      branches: []
    }
  })

  const handleFileSelect = (file) => {
    setValue('pdf', file, { shouldValidate: true })
    if (file) {
      const url = URL.createObjectURL(file)
      setPdfUrl(url)
    } else {
      setPdfUrl(null)
    }
  }

  console.log('Form values before submit:', control._formValues)

  const onSubmit = async (data) => {
    console.log('onSubmit called with data:', data)
    setIsLoading(true)
    try {
      const formData = new FormData()
      formData.append('pdf', data.pdf)
      formData.append('title', data.title)
      formData.append('expiryTime', data.expiryTime.toISOString())
      formData.append('branches', JSON.stringify(data.branches))

      const response = await (innerProps?.defaultValues?.id
        ? haxios.put(`/promotions/${innerProps?.defaultValues?.id}`, {
            title: data.title,
            expiryTime: data.expiryTime.toISOString(),
            branches: JSON.stringify(data.branches)
          })
        : haxios.post('/promotions/upload', formData))

      if (innerProps?.refetch) {
        await innerProps.refetch()
      }

      handleClose()
    } catch (error) {
      console.error('Error uploading promotion:', error)
    } finally {
      setIsLoading(false)
    }
  }

  return (
    <ModalContainerAnimateDown ref={modalContainer}>
      <Card className="w-[950px] p-4">
        <form
          onSubmit={(e) => {
            console.log('Form submit event triggered')
            e.preventDefault()
            return handleSubmit(onSubmit)(e)
          }}
        >
          <Grid>
            {/* Left Side - Form */}
            <Grid.Col span={7}>
              <Grid>
                <Grid.Col span={6}>
                  <TextInput
                    label="Promotion title"
                    name="title"
                    control={control}
                    error={errors.title?.message}
                  />
                </Grid.Col>
                <Grid.Col span={6}>
                  <Controller
                    name="expiryTime"
                    control={control}
                    render={({ field }) => (
                      <DateTimePicker
                        popoverProps={{ zIndex: 10000 }}
                        label="Expiry time"
                        minDate={new Date()}
                        value={field.value}
                        onChange={(date) => {
                          field.onChange(date)
                          setValue('expiryTime', date, { shouldValidate: true })
                        }}
                        error={errors.expiryTime?.message}
                      />
                    )}
                  />
                </Grid.Col>
                <Grid.Col span={6}>
                  {!innerProps?.defaultValues?.pdf && (
                    <FileInput
                      label="Select PDF"
                      name="pdf"
                      control={control}
                      accept="application/pdf"
                      onChange={handleFileSelect}
                      placeholder="Select PDF file"
                      error={errors.pdf?.message}
                    />
                  )}
                </Grid.Col>

                <Grid.Col span={12}>
                  <div className="flex items-center space-x-2">
                    <div className="font-semibold">Select garages</div>
                  </div>
                  {errors.branches && (
                    <Text color="red" size="sm" mt={2}>
                      {errors.branches.message}
                    </Text>
                  )}
                </Grid.Col>

                <Grid.Col span={12}>
                  <div className="h-[365px] overflow-y-scroll">
                    <Controller
                      name="branches"
                      control={control}
                      render={({ field }) => (
                        <>
                          <Checkbox
                            label={`Select all`}
                            checked={field.value.length === garageList.length}
                            onChange={(e) => {
                              if (e.currentTarget.checked) {
                                field.onChange(garageList.map((e) => e.CustomerKeyCode))
                              } else {
                                field.onChange([])
                              }
                            }}
                          />
                          {garageList.map((garage) => (
                            <div key={garage} className="py-1">
                              <Checkbox
                                label={`[${garage.CustomerKeyCode}] ${garage.GarageName}`}
                                checked={field.value.includes(garage.CustomerKeyCode)}
                                onChange={(e) => {
                                  const updatedBranches = e.currentTarget.checked
                                    ? [...field.value, garage.CustomerKeyCode]
                                    : field.value.filter((b) => b !== garage.CustomerKeyCode)
                                  field.onChange(updatedBranches)
                                }}
                              />
                            </div>
                          ))}
                        </>
                      )}
                    />
                  </div>
                </Grid.Col>

                <Grid.Col span={12}>
                  <div className="flex space-x-4">
                    <Button variant="outline" onClick={handleClose} disabled={isLoading}>
                      Cancel
                    </Button>
                    <Button type="submit" loading={isLoading}>
                      {innerProps?.defaultValues?.id ? 'Save' : 'Upload'}
                    </Button>
                  </div>
                </Grid.Col>
              </Grid>
            </Grid.Col>

            {/* Right Side - Preview */}
            <Grid.Col span={5}>
              <div className="overflow-hidden w-full h-full rounded-md border border-gray-300">
                {pdfUrl || innerProps?.defaultValues?.pdf ? (
                  <iframe
                    src={pdfUrl || innerProps?.defaultValues?.pdf}
                    className="w-full h-full"
                    title="PDF Preview"
                  />
                ) : (
                  <div className="flex justify-center items-center w-full h-full bg-gray-100">
                    <Text>No PDF selected</Text>
                  </div>
                )}
              </div>
            </Grid.Col>
          </Grid>
        </form>
      </Card>
    </ModalContainerAnimateDown>
  )
})

export default PromotionUploadModal
