import { Button, Grid, Text } from '@mantine/core'
import useApi from '../hooks/useApi'
import moment from 'moment-timezone'
import { useState } from 'react'
import { MagnifyingGlassIcon } from '@heroicons/react/24/outline'
import { Card } from '@mui/material'
import NiceModal from '@ebay/nice-modal-react'
import { yupResolver } from '@hookform/resolvers/yup'
import { useForm, Controller } from 'react-hook-form'
import { TextInput, FileInput, Textarea } from 'react-hook-form-mantine'
import { DateTimePicker } from '@mantine/dates'
import yup from '../lib/yup'
import { Checkbox } from '@mantine/core'
import haxios from '../lib/haxios'
import { ModalContainerAnimateDown } from './gsys-ui/ModalContainer/ModalContainer'
import { groupByKey, successToast } from '../lib/util'

const validation = yup.object().shape({
  heading: yup.string().required('Title is required'),
  subtitle: yup.string(),
  contents: yup.string(),
  image: yup
    .mixed()
    .nullable()
    .test('fileType', 'File must be an image (JPG or PNG)', (value) => {
      if (!value) return true // Allow null/undefined values
      return value.type === 'image/jpeg' || value.type === 'image/jpg' || value.type === 'image/png'
    }),
  playerIds: yup
    .array()
    .of(yup.string())
    .min(1, 'Select at least one user')
    .required('Select at least one user')
})

const Notifications = () => {
  const [isLoading, setIsLoading] = useState(false)
  const [imageUrl, setImageUrl] = useState(null)
  const [submitError, setSubmitError] = useState(null)

  const devices = useApi({
    url: '/garage/devices',
    initial: []
  })

  const devicesGrouped = groupByKey('Acct', devices.data)

  const {
    control,
    handleSubmit,
    setValue,
    reset,
    watch,
    formState: { errors }
  } = useForm({
    resolver: yupResolver(validation),
    defaultValues: {
      heading: '',
      subtitle: '',
      contents: '',
      image: null,
      playerIds: []
    }
  })

  const handleFileSelect = (file) => {
    setValue('image', file, { shouldValidate: true })
    if (file) {
      const url = URL.createObjectURL(file)
      setImageUrl(url)
    } else {
      setImageUrl(null)
    }
  }

  const onSubmit = async (data) => {
    // Form validation will prevent this function from being called if validation fails
    console.log('onSubmit called with data:', data)
    setIsLoading(true)
    setSubmitError(null)
    try {
      const formData = new FormData()
      formData.append('image', data.image)
      const imageResponse = data.image
        ? await haxios.post('/promotions/notificationImageUpload', formData)
        : null

      const json = {
        playerIds: data.playerIds,
        heading: data.heading,
        contents: data.contents,
        subtitle: data.subtitle,
        image: imageResponse?.url,
        data: {
          navigateTo: 'Notification',
          itemId: '12345'
        }
      }

      await haxios.post('/onesignal/sendNotification', json)

      successToast('Notification sent!')
      reset()
      setImageUrl(null)
    } catch (error) {
      console.error('Error uploading promotion:', error)
      setSubmitError('Failed to send notification. Please try again.')
    } finally {
      setIsLoading(false)
    }
  }

  return (
    <div>
      <div className="flex justify-between items-center p-2 mb-2">
        <div className="text-lg font-semibold">Send a notification</div>
      </div>

      <form
        onSubmit={(e) => {
          e.preventDefault()
          return handleSubmit(onSubmit)(e)
        }}
      >
        {submitError && <div className="mb-4 font-semibold text-red-500">{submitError}</div>}

        <Grid>
          {/* Left Side - Form */}
          <Grid.Col span={6}>
            <Grid>
              <Grid.Col span={6}>
                <TextInput
                  label="Title"
                  name="heading"
                  control={control}
                  error={errors.heading?.message}
                />
              </Grid.Col>
              <Grid.Col span={6}>
                <TextInput
                  label="Subtitle (optional)"
                  name="subtitle"
                  control={control}
                  error={errors.subtitle?.message}
                />
              </Grid.Col>
              <Grid.Col span={12}>
                <Textarea
                  label="Contents"
                  name="contents"
                  rows={5}
                  control={control}
                  error={errors.contents?.message}
                />
              </Grid.Col>
              <Grid.Col span={6}>
                <FileInput
                  label="Select Image"
                  name="image"
                  control={control}
                  accept="image/jpeg,image/jpg,image/png"
                  onChange={handleFileSelect}
                  placeholder="Select image file"
                  error={errors.image?.message}
                />
              </Grid.Col>

              <Grid.Col span={12}>
                <div className="flex items-center space-x-2">
                  <div className="font-semibold">Select users</div>
                </div>
                {errors.branches && (
                  <Text color="red" size="sm" mt={2}>
                    {errors.branches.message}
                  </Text>
                )}
              </Grid.Col>

              <Grid.Col span={12}>
                <Controller
                  name="playerIds"
                  control={control}
                  render={({ field }) => (
                    <div className="h-[365px] overflow-y-scroll">
                      <div className="mb-3 font-semibold">
                        <Checkbox
                          label={`Select all`}
                          checked={field.value.length === devices.data.length}
                          onChange={(e) => {
                            if (e.currentTarget.checked) {
                              field.onChange(devices.data.map((e) => e.OneSignalId))
                            } else {
                              field.onChange([])
                            }
                          }}
                        />
                      </div>
                      {Object.values(devicesGrouped).map((garage) => {
                        const garageAcct = garage[0].Acct
                        const garageName = garage[0].GarageName
                        const garageUsers = garage.map((u) => u.OneSignalId)

                        const garageIsSelected = (field) => {
                          return garageUsers.every((u) => field.value.includes(u))
                        }

                        return (
                          <div key={garageAcct} className="mb-3">
                            <Checkbox
                              label={`[${garageAcct}] ${garageName}`}
                              checked={garageIsSelected(field)}
                              onChange={(e) => {
                                let newUsers = [...field.value]

                                if (e.currentTarget.checked) {
                                  garageUsers.forEach((osId) => {
                                    if (!newUsers.includes(osId)) {
                                      newUsers.push(osId)
                                    }
                                  })
                                } else {
                                  newUsers = newUsers.filter((osId) => !garageUsers.includes(osId))
                                }

                                field.onChange(newUsers)
                              }}
                            />
                            <div className="relative pt-1 pl-6">
                              <div className="absolute w-[1px] bg-gray-300 left-[9px] top-1 bottom-1" />
                              {garage.map((user) => (
                                <div key={user.OneSignalId} className="py-1">
                                  <Checkbox
                                    label={`${user.GarageUserName}`}
                                    checked={field.value.includes(user.OneSignalId)}
                                    onChange={(e) => {
                                      const updatedUsers = e.currentTarget.checked
                                        ? [...field.value, user.OneSignalId]
                                        : field.value.filter((b) => b !== user.OneSignalId)
                                      field.onChange(updatedUsers)
                                    }}
                                  />
                                </div>
                              ))}
                            </div>
                          </div>
                        )
                      })}
                    </div>
                  )}
                />
              </Grid.Col>

              <Grid.Col span={12}>
                <div className="flex space-x-4">
                  <Button type="submit" loading={isLoading}>
                    Send
                  </Button>
                </div>
              </Grid.Col>
            </Grid>
          </Grid.Col>

          <Grid.Col span={6}>
            <div className="overflow-hidden p-4 w-full rounded-md border border-gray-300">
              <div className="mb-4 text-lg font-semibold">Preview</div>

              <div className="flex items-center px-4 py-4 bg-gray-200 rounded-3xl">
                <div className="flex-none mr-4">
                  <img
                    src="https://www.melkshammotorspares.co.uk/images/mms-initials-logo-2019-500-002.png"
                    className="w-[60px] h-[60px] object-contain bg-white rounded-xl"
                  />
                </div>

                <div className="flex-1">
                  <div className="text-lg font-semibold leading-snug truncate">
                    {watch('heading')}
                  </div>
                  <div className="text-lg font-semibold leading-snug truncate">
                    {watch('subtitle')}
                  </div>
                  <div className="text-lg leading-snug truncate">{watch('contents')}</div>
                </div>

                <div className="flex-none">
                  {imageUrl && (
                    <img
                      src={imageUrl}
                      alt="Preview"
                      className="object-cover w-[40px] h-[40px] rounded-lg"
                    />
                  )}
                </div>
              </div>
            </div>
          </Grid.Col>
        </Grid>
      </form>
    </div>
  )
}

export default Notifications
