import { Button, TextInput } from '@mantine/core'
//import { modals } from '@mantine/modals'
import useApi from '../hooks/useApi'
import moment from 'moment-timezone'
//import { successToast } from './lib/helpers'
import haxios from '../lib/haxios'
import { useState } from 'react'
import { MagnifyingGlassIcon } from '@heroicons/react/24/outline'
import { Card } from '@mui/material'
import NiceModal from '@ebay/nice-modal-react'

const Promotions = () => {
  const [searchValue, setSearchValue] = useState('')

  const { data: promotions, refetch } = useApi({
    url: '/promotions',
    initial: []
  })

  const promotionsFiltered = promotions
  // .filter(
  //   (el) =>
  //     el.Title.toLowerCase().includes(searchValue.toLowerCase()) &&
  //     (selectedBranch === 'ALL' || el.Branches.includes(selectedBranch)) &&
  //     (selectedRegion
  //       ? regions[selectedRegion].some((b) => el.Branches.includes(b.MamCode))
  //       : true)
  // )
  // .sort((a, b) => moment(a.ExpiryTime).isAfter(b.ExpiryTime))

  const handlePromotionModal = () => {
    NiceModal.show('promotionUploadModal', { refetch })
  }

  const handleEditModal = (p) => {
    console.log(p)
    NiceModal.show('promotionUploadModal', {
      refetch,
      defaultValues: {
        id: p._id,
        title: p.Title,
        expiryTime: moment(p.ExpiryTime).toDate(),
        pdf: p.FilePath,
        branches: p.Branches
      }
    })
  }

  const handleDelete = (p) => {
    NiceModal.show('warningModal', {
      message: `You are about to delete the promotion "${p.Title}".`,
      submit: async () => {
        await haxios.delete(`/promotions/${p._id}`)
        await refetch()
        //successToast(`Promotion deleted.`)
      }
    })
  }

  return (
    <div>
      <div className="flex justify-between items-center p-2 mb-2">
        <div className="text-lg font-semibold">Running promotions</div>
        <div className="flex items-center space-x-2">
          <TextInput
            value={searchValue}
            onChange={(e) => setSearchValue(e.target.value)}
            placeholder="Type to search..."
            leftSection={<MagnifyingGlassIcon className="w-5 h-5" />}
          />
          <Button onClick={handlePromotionModal}>Add promotion</Button>
        </div>
      </div>

      <div className="flex flex-wrap">
        {promotionsFiltered.map((p) => (
          <Card key={p._id} className="inline-block !w-auto m-2">
            <div className="flex">
              <img src={p.ThumbnailPath} />
              <div className="w-[150px] flex flex-col p-1">
                <div className="flex-1">
                  <div className="font-semibold">{p.Title}</div>
                  <div className="text-sm">Expires</div>
                  <div className="text-sm font-semibold">
                    {moment(p.ExpiryTime).format('DD/MM/YYYY HH:mm')}
                  </div>
                </div>
                <div className="flex flex-none justify-between p-1">
                  <Button size="xs" color="red" onClick={() => handleDelete(p)}>
                    Delete
                  </Button>
                  <Button size="xs" onClick={() => handleEditModal(p)}>
                    Edit
                  </Button>
                </div>
              </div>
            </div>
          </Card>
        ))}
      </div>
    </div>
  )
}

export default Promotions
