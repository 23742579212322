import React, { useState } from 'react';
import {
    Box,
    Button,
    Card,
    CardContent,
    FormControl,
    Grid,
    InputLabel,
    MenuItem,
    Select,
    TextField,
    Typography,
    Alert,
    CircularProgress
} from '@mui/material';
import dayjs from 'dayjs';
import isBetween from 'dayjs/plugin/isBetween';
import _ from 'lodash';
import useApi from '../hooks/useApi';

dayjs.extend(isBetween);

const ReportingDashboard = () => {
    const [startDate, setStartDate] = useState(dayjs().startOf('week').format('YYYY-MM-DD'));
    const [endDate, setEndDate] = useState(dayjs().endOf('week').format('YYYY-MM-DD'));
    const [reportType, setReportType] = useState('customer');
    const [timeGroup, setTimeGroup] = useState('daily');
    const [error, setError] = useState('');

    // Use the same API hook as the Enquiries component
    const {
        isLoading,
        isFetching,
        data: enquiries = [],
        refetch
    } = useApi({
        url: 'https://api.aa-vision.com/api/connectplus/factor/enquiries/',
        method: 'post',
        data: { StartDate: startDate, EndDate: endDate },
        refetch: 30 * 1000
    });

    const generateCustomerReport = (filteredEnquiries) => {
        if (!filteredEnquiries?.length) return [];

        // Group by customer
        const customerGroups = _.groupBy(filteredEnquiries, 'GarageName');

        const customerStats = _.map(customerGroups, (customerEnquiries, customer) => {
            const quotes = customerEnquiries.filter(e => e.Status === 'Quoted').length;
            const orders = customerEnquiries.filter(e => e.Status === 'Ordered').length;
            const cancelled = customerEnquiries.filter(e => e.Status === 'Cancelled').length;
            const quotesValue = _.sumBy(customerEnquiries.filter(e => e.Status === 'Quoted'), 'TotalCost') || 0;
            const ordersValue = _.sumBy(customerEnquiries.filter(e => e.Status === 'Ordered'), 'TotalCost') || 0;

            return {
                Customer: customer || 'Unknown',
                Quotes: quotes,
                Orders: orders,
                Cancelled: cancelled,
                'Quotes Value': quotesValue.toFixed(2),
                'Orders Value': ordersValue.toFixed(2),
                'Conversion Rate': quotes > 0 ? ((orders / quotes) * 100).toFixed(2) + '%' : '0%'
            };
        });

        return customerStats;
    };

    const generateOperatorReport = (filteredEnquiries) => {
        if (!filteredEnquiries?.length) return [];

        // Group by operator (Username)
        const operatorGroups = _.groupBy(filteredEnquiries, 'Username');

        const operatorStats = _.map(operatorGroups, (operatorEnquiries, operator) => {
            const quotes = operatorEnquiries.filter(e => e.Status === 'Quoted').length;
            const orders = operatorEnquiries.filter(e => e.Status === 'Ordered').length;
            const cancelled = operatorEnquiries.filter(e => e.Status === 'Cancelled').length;
            const quotesValue = _.sumBy(operatorEnquiries.filter(e => e.Status === 'Quoted'), 'TotalCost') || 0;
            const ordersValue = _.sumBy(operatorEnquiries.filter(e => e.Status === 'Ordered'), 'TotalCost') || 0;

            return {
                Operator: operator || 'Unknown',
                Quotes: quotes,
                Orders: orders,
                Cancelled: cancelled,
                'Quotes Value': quotesValue.toFixed(2),
                'Orders Value': ordersValue.toFixed(2),
                'Conversion Rate': quotes > 0 ? ((orders / quotes) * 100).toFixed(2) + '%' : '0%'
            };
        });

        return operatorStats;
    };

    const downloadCSV = () => {
        try {
            setError('');

            if (!enquiries?.length) {
                setError('No data available for the report');
                return;
            }

            // Filter enquiries by date range
            const filteredEnquiries = enquiries.filter(enquiry => {
                if (!enquiry?.DatetimeCreated) return false;
                const enquiryDate = dayjs(enquiry.DatetimeCreated);
                return enquiryDate.isBetween(startDate, endDate, 'day', '[]');
            });

            if (!filteredEnquiries.length) {
                setError('No data found for the selected date range');
                return;
            }

            let reportData;
            if (reportType === 'customer') {
                reportData = generateCustomerReport(filteredEnquiries);
            } else {
                reportData = generateOperatorReport(filteredEnquiries);
            }

            if (!reportData.length) {
                setError('No data available for the selected report type');
                return;
            }

            // Convert data to CSV format
            const headers = Object.keys(reportData[0]).join(',');
            const rows = reportData.map(row =>
                Object.values(row).map(value =>
                    typeof value === 'string' && value.includes(',') ? `"${value}"` : value
                ).join(',')
            );
            const csv = [headers, ...rows].join('\n');

            // Create download
            const blob = new Blob([csv], { type: 'text/csv' });
            const url = window.URL.createObjectURL(blob);
            const a = document.createElement('a');
            a.href = url;
            a.download = `${reportType}-report-${startDate}-to-${endDate}.csv`;
            document.body.appendChild(a);
            a.click();
            document.body.removeChild(a);
            window.URL.revokeObjectURL(url);

        } catch (err) {
            console.error('Error generating report:', err);
            setError('Error generating report. Please try again.');
        }
    };

    return (
        <Card>
            <CardContent>
                <Grid container spacing={3}>
                    <Grid item xs={12}>
                        <Typography variant="h5" gutterBottom>
                            Generate Reports
                        </Typography>
                    </Grid>

                    {error && (
                        <Grid item xs={12}>
                            <Alert severity="error">{error}</Alert>
                        </Grid>
                    )}

                    {isLoading ? (
                        <Grid item xs={12} sx={{ display: 'flex', justifyContent: 'center' }}>
                            <CircularProgress />
                        </Grid>
                    ) : (
                        <>
                            <Grid item xs={12} md={4}>
                                <FormControl fullWidth>
                                    <InputLabel>Report Type</InputLabel>
                                    <Select
                                        value={reportType}
                                        label="Report Type"
                                        onChange={(e) => setReportType(e.target.value)}
                                    >
                                        <MenuItem value="customer">Customer Report</MenuItem>
                                        <MenuItem value="operator">Operator Report</MenuItem>
                                    </Select>
                                </FormControl>
                            </Grid>

                            <Grid item xs={12} md={4}>
                                <FormControl fullWidth>
                                    <InputLabel>Time Grouping</InputLabel>
                                    <Select
                                        value={timeGroup}
                                        label="Time Grouping"
                                        onChange={(e) => setTimeGroup(e.target.value)}
                                    >
                                        <MenuItem value="daily">Daily</MenuItem>
                                        <MenuItem value="weekly">Weekly</MenuItem>
                                        <MenuItem value="monthly">Monthly</MenuItem>
                                    </Select>
                                </FormControl>
                            </Grid>

                            <Grid item xs={12} md={4}>
                                <Box sx={{ display: 'flex', gap: 2 }}>
                                    <TextField
                                        label="Start Date"
                                        type="date"
                                        value={startDate}
                                        onChange={(e) => setStartDate(e.target.value)}
                                        InputLabelProps={{
                                            shrink: true,
                                        }}
                                        fullWidth
                                    />
                                    <TextField
                                        label="End Date"
                                        type="date"
                                        value={endDate}
                                        onChange={(e) => setEndDate(e.target.value)}
                                        InputLabelProps={{
                                            shrink: true,
                                        }}
                                        fullWidth
                                    />
                                </Box>
                            </Grid>

                            <Grid item xs={12}>
                                <Button
                                    variant="contained"
                                    color="primary"
                                    onClick={downloadCSV}
                                    fullWidth
                                    disabled={isLoading || isFetching}
                                >
                                    {isLoading || isFetching ? 'Loading...' : 'Download Report'}
                                </Button>
                            </Grid>
                        </>
                    )}
                </Grid>
            </CardContent>
        </Card>
    );
};

export default ReportingDashboard;