import React from 'react'
import packageJson from '../../package.json'
import moment from 'moment'

const versionNotes = [
  {
    version: '0.5.3',
    date: moment('2025-03-20'),
    notes: [
      'Made backordered enquiries ignore the backordered filter if they have a new message',
      'Added a status chip to flag backordered enquiries'
    ]
  },
  {
    version: '0.5.2',
    date: moment('2025-03-18'),
    notes: ['Made the priority section respect backorder filtering']
  },
  {
    version: '0.5.1',
    date: moment('2025-03-16'),
    notes: [
      'Added 1 year lookback for priority enquiries outside the selected date rage (updates hourly)',
      'Added clearing locks when a user disconnects',
      'Clear existing locks for a user when locking an enquiry'
    ]
  },
  {
    version: '0.5.0',
    date: moment('2025-02-23'),
    notes: [
      'Added staff notifications for enquiries',
      'Fixed crash when clicking the message button'
    ]
  },
  {
    version: '0.4.1',
    date: moment('2025-02-22'),
    notes: [
      'Fixed new licenses overwriting old licenses',
      'Fixed license add modal not clearing when it closes'
    ]
  },
  {
    version: '0.4.0',
    date: moment('2025-02-20'),
    notes: ['Added notifications center']
  },
  {
    version: '0.3.0',
    date: moment('2025-02-15'),
    notes: ['Added promotional PDF upload']
  },
  {
    version: '0.2.2',
    date: moment('2025-01-18'),
    notes: ['Fixed lines reloading on status change', 'Added filter for backordered enquiries']
  },
  {
    version: '0.2.1',
    date: moment('2025-01-16'),
    notes: [
      'Added this page!',
      'Added modal for creating new customers',
      'Fixed sidebar not opening/closing correctly',
      'Updated sidebar icons'
    ]
  },
  {
    version: '0.2.0',
    date: moment('2025-01-15'),
    notes: [
      'Created new low-latency locking system',
      'If an enquiry is locked, only admins can open the modal',
      'Added unlock button to the enquiries list for admins'
    ]
  }
]

function About() {
  return (
    <div>
      <div className="mb-4">
        <div className="text-2xl font-semibold">About</div>
        <div className="mt-2 font-semibold">Version: {packageJson.version}</div>
      </div>
      <div className="">
        <div className="mb-2 text-xl font-semibold">Changelog</div>
        {versionNotes.map((ver, ind) => {
          return (
            <React.Fragment key={ver.version}>
              <div className="mt-2 text-lg font-semibold">
                {ver.version} {ver.version === packageJson.version && '(current)'}{' '}
                {ver.date && <span className="pl-2 text-sm">{ver.date.format('DD/MM/YYYY')}</span>}
              </div>
              <ul className="py-1 pl-2 ml-1 border-l border-l-gray-400">
                {ver.notes.map((note, ind) => (
                  <li key={note} className="mb-0.5 ml-4 list-disc">
                    {note}
                  </li>
                ))}
              </ul>
            </React.Fragment>
          )
        })}
      </div>
    </div>
  )
}

export default About
